import React, { useEffect, useState } from "react";
import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
	RouterProvider,
	useRouteError,
} from "react-router-dom";
import { v4 as uuid } from "uuid";
import jwt_decode from "jwt-decode";
import { GoogleOAuthProvider } from "@react-oauth/google";
import api from "./components/api";
import Brands from "./pages/Brands";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import Store from "./pages/Store";
import NotFound from "./pages/NotFound";
import Cms from "./pages/Cms";
import "./css/App.css";
import "./css/Forms.css";
import "./css/Header.css";
import "./css/Menu.css";
import "./css/Store.css";
import "./css/Cart.css";
import "./css/PopUp.css";
import "./css/ProductSheet.css";
import "./css/Login.css";
import "./css/Userboard.css";
import "./css/Checkout.css";
import "./css/Buttons.css";
import "./css/Shop.css";
import "./css/PromoLevels.css";
import "./css/Booking.css";
import "./css/Home.css";
import "./css/CmsMenu.css";
import "./css/Footer.css";
import "./css/cms-popups.css";
import "./css/Maintenance.css";
import "./css/CookieMan.css";
import Shop from "./pages/Shop";
import Login from "./pages/Login";
import LoginEmailLogin from "./pages/LoginEmailLogin";
import LoginCheckEmail from "./pages/LoginCheckEmail";
import Userboard from "./pages/Userboard";
import BillingAddress from "./pages/BillingAddress";
import Orders from "./pages/Orders";
import { getCookie, setCookie } from "./components/functions";
import Bookings from "./pages/Bookings";
import Checkout from "./pages/Checkout";
import OrderConfirmed from "./pages/OrderConfirmed";
import PagePopup from "./pages/PagePopup";
import PointsTransactions from "./pages/PointsTransactions";
import CreditTransactions from "./pages/CreditTransactions";
import SignUp from "./pages/SignUp";
import CheckCode from "./pages/CheckCode";
import CheckPasswordCode from "./pages/CheckPasswordCode";
import NewPassword from "./pages/NewPassword";
import Booking from "./pages/Booking";
import BookingConfirmed from "./pages/BookingConfirmed";
import Promo from "./pages/Promo";
import ReactPixel from "react-facebook-pixel";
import Label from "./components/Label";
import NewsList from "./pages/NewsList";
import News from "./pages/News";
import Shops from "./pages/Shops";
import BookingSheet from "./pages/BookingSheet";
import CmsPopups from "./components/CmsPopups";
import RestorePassword from "./pages/RestorePassword";
import moment from "moment";
import ReactGA from "react-ga4";
import LoginCheckMobile from "./pages/LoginCheckMobile";
import SignUpMobile from "./pages/SignUpMobile";
import CheckCodeMobile from "./pages/CheckCodeMobile";
import LoginMobileLogin from "./pages/LoginMobileLogin";
import CheckPasswordCodeSms from "./pages/CheckPasswordCodeSms";
import TagManager from "react-gtm-module";
import RelatedProductsPopup from "./pages/RelatedProductsPopup";
import ProductSheet from "./pages/ProductSheet";
import StorePopUp from "./components/StorePopUp";
import Confirm from "./components/confirm";
import Maintenance from "./components/Maintenance";
import LoadIcon from "./components/icons/LoadIcon";

export const AppContext = React.createContext();

const savedBrandCookie = getCookie("website-brand");
let savedBrand = false;
if (savedBrandCookie == "main") savedBrand = "main";
else if (savedBrandCookie != "") savedBrand = JSON.parse(savedBrandCookie);
console.log("savedBrand", savedBrand);

function App() {
	const queryParams = new URLSearchParams(window.location.search);
	const [lock, setLock] = useState(true);
	const [lang, setLang] = useState(
		localStorage.getItem("website-lang") ? localStorage.getItem("website-lang") : "it"
	);
	const [tempId, setTempId] = useState(
		localStorage.getItem("website-temp-id") ? localStorage.getItem("website-temp-id") : false
	);
	const [loading, setLoading] = useState(false);
	const [settings, setSettings] = useState(false);
	const [labels, setLabels] = useState(false);
	const [pages, setPages] = useState([]);
	const [shops, setShops] = useState([]);
	const [brand, setBrand] = useState(savedBrand);
	const [menu, setMenu] = useState([]);
	const [courses, setCourses] = useState([]);
	const [shop, setShop] = useState(false);
	const [listId, setListId] = useState(false);
	const [category, setCategory] = useState(false);
	const [user, setUser] = useState(false);
	const [cart, setCart] = useState(false);
	const [confirm, setConfirm] = useState(false);
	const [lastOrderId, setLastOrderId] = useState(false);
	const [lastOrderTotal, setLastOrderTotal] = useState(0);
	const [pagePopup, setPagePopup] = useState(false);
	const [tempUser, setTempUser] = useState({ id: "", area_code: "+39", mobile: "", email: "" });
	const [loginPopup, setLoginPopup] = useState(false);
	const [discounts, setDiscounts] = useState(false);
	const [news, setNews] = useState(false);
	const [menus, setMenus] = useState(false);
	const [slides, setSlides] = useState(false);
	const [popups, setPopups] = useState([]);
	const [mainSettings, setMainSettings] = useState(false);
	const [hotel, setHotel] = useState(queryParams.get("hotel_id"));
	const [hasOrders, setHasOrders] = useState(false);
	const [hasReservations, setHasReservations] = useState(false);
	const [hasDelivery, setHasDelivery] = useState(false);
	const [hasTakeaway, setHasTakeaway] = useState(false);
	const [loginRedirect, setLoginRedirect] = useState(false);
	const [c1, setC1] = useState(getCookie("website-cookies-a"));
	const [cookieSettingsPopupVisible, setCookieSettingsPopupVisible] = useState(false);
	const [relatedProducts, setRelatedProducts] = useState(false);
	const [loadRelated, setLoadRelated] = useState(true);
	const [cartEnabled, setCartEnabled] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(false);
	const [storePopupVisible, setStorePopupVisible] = useState(false);
	const [discountsLimit, setDiscountsLimit] = useState([]);
	const [shopLocked, setShopLocked] = useState(false);
	const [stock, setStock] = useState([]);

	useEffect(() => {
		init(); // eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (lang) localStorage.setItem("website-lang", lang);
	}, [lang]);

	useEffect(() => {
		console.log("tempUser set to", tempUser);
	}, [tempUser]);

	useEffect(() => {
		if (menu) filterMenu();
	}, [menu, lang, listId, shop, discounts]);

	useEffect(() => {
		loadCart();
	}, [lang, user, hotel, shopLocked]);

	useEffect(() => {
		console.log("brand set to", brand);
		if (brand) {
			if (brand == "main") api.setHostname(window.location.hostname);
			else api.setHostname(brand.host);
			setLock(true);
			loadSettings();
			if (brand == "main") setCookie("website-brand", brand, false);
			else setCookie("website-brand", JSON.stringify(brand), false);
		}
	}, [brand]);

	useEffect(() => {
		if (settings) {
			setCartEnabled(settings.website.store_popup_auto == 1);
			loadBaseData();
			console.log("Init Tag Manager", settings.website.google_analytics);
			if (settings.website.google_analytics) {
				console.log("Init GA");
				ReactGA.initialize(settings.website.google_analytics);
			}
			if (settings.website.googletagmanager) {
				console.log("Init Tag Manager");
				const tagManagerArgs = {
					gtmId: settings.website.googletagmanager,
				};
				TagManager.initialize(tagManagerArgs);
			}
		}
	}, [settings]);

	useEffect(() => {
		if (category && settings && settings.website.google_analytics) {
			console.log("Track GA");
			ReactGA.send({
				hitType: "pageview",
				page: "/cat-" + category.id,
				title: category.name,
			});
		}
	}, [category]);

	useEffect(() => {
		let orders = false;
		let reservations = false;
		let delivery = false;
		let takeaway = false;
		if (shops) {
			for (const shop of shops) {
				if (shop.delivery == 1 || shop.takeaway == 1) orders = true;
				if (shop.active_reservations == 1) reservations = true;
				if (shop.delivery == 1) delivery = true;
				if (shop.takeaway == 1) takeaway = true;
			}
		}
		setHasOrders(orders);
		setHasReservations(reservations);
		setHasDelivery(delivery);
		setHasTakeaway(takeaway);
	}, [shops]);

	useEffect(() => {
		console.log("loginRedirect set to", loginRedirect);
	}, [loginRedirect]);

	useEffect(() => {
		if (cart) {
			loadDiscounts();
			loadDiscountsLimit();
			loadProductsStock();
		}
	}, [cart]);

	const init = async () => {
		console.log("init");
		api.setBaseUrl();
		loadSettings(true);
		if (queryParams.get("shop_id")) {
			setShopLocked(queryParams.get("shop_id"));
			localStorage.setItem("website-shop_id", queryParams.get("shop_id"));
		}
		if (localStorage.getItem("website-shop_id")) {
			setShopLocked(localStorage.getItem("website-shop_id"));
		}
	};

	const loadBaseData = async () => {
		console.log("loadBaseData");
		setLock(true);
		applyCss();
		initFbPixel();
		await loadMenus();
		await loadLabels();
		await loadPages();
		await loadSlides();
		await loadNews();
		await loadShops();
		await loadMenu();
		await loadPopups();
		await restoreUser();
		setLock(false);
	};

	const initFbPixel = () => {
		if (!settings.website.facebookpixel) return;
		console.log("initFbPixel", settings.website.facebookpixel);
		const options = {
			autoConfig: false,
			debug: true,
		};
		ReactPixel.init(settings.website.facebookpixel, undefined, options);

		ReactPixel.pageView();
	};

	const track = (event = false) => {
		if (settings.website.facebookpixel) ReactPixel.pageView();

		if (settings.website.googletagmanager) {
			window.dataLayer.push({
				event: "pageview",
			});
		}
	};

	const restoreUser = async () => {
		console.log("restoreUser");
		const token = localStorage.getItem("website-user");
		console.log(token);
		if (token && token !== "null") {
			const refresh = await api.refreshToken(token);
			if (refresh.success == 0) alert(refresh.error);
			else {
				localStorage.setItem("pos-user", refresh.token);
				const decoded = jwt_decode(refresh.token);
				const response = await api.getProtected("/customers2/get/" + decoded.user_id + "/");
				if (response.success == 0) alert(response.error);
				else setUser(response.data);
			}
		}
	};

	const reloadUser = async () => {
		if (user) {
			const response = await api.getProtected("/customers2/get/" + user.id + "/");
			if (response.success == 0) alert(response.error);
			else setUser(response.data);
		}
	};

	const loadSettings = async (is_main = false) => {
		console.log("loadSettings", is_main);
		const res = await api.post("/settings2/get/", {
			section: [
				"global",
				"website",
				"google",
				"fidelity",
				"customers",
				"orders",
				"reservations",
				"menu",
				"credit",
				"socials",
				"newsletter",
				"menu_api",
			],
		});
		if (res.success == 0) alert(res.error);
		else {
			if (is_main) {
				setMainSettings(res.data);
				if (res.data.mandant.childs.length == 0) setBrand("main");

				if (res.data.mandant.childs.length > 0 && queryParams.get("brand_id")) {
					let found = false;
					for (const b of res.data.mandant.childs) {
						if (b.id == queryParams.get("brand_id")) {
							found = true;
							setBrand(b);
						}
					}
					if (!found) setBrand("main");
				}
			}
			setSettings(res.data);

			document.title = res.data.global.name;
			var link =
				document.querySelector("link[rel*='icon']") || document.createElement("link");
			link.type = "image/x-icon";
			link.rel = "shortcut icon";
			link.href = res.data.website.favicon;
			document.getElementsByTagName("head")[0].appendChild(link);
		}
	};

	const loadMenus = async () => {
		console.log("loadMenus");
		const res = await api.post("/cms_menus/list/", {
			src: [{ name: "active", value: 1, compare: "equal" }],
		});
		if (res.success == 0) alert(res.error);
		else setMenus(res.rows);
	};

	const applyCss = () => {
		console.log("applyCss");

		document.documentElement.style.setProperty("--c1", settings.website.color5);
		document.documentElement.style.setProperty("--c1c", settings.website.color6);
		document.documentElement.style.setProperty("--c1o", settings.website.color5 + "50");
		document.documentElement.style.setProperty("--c2", settings.website.color3);
		document.documentElement.style.setProperty("--c2c", settings.website.color4);
		document.documentElement.style.setProperty("--c3", settings.website.color1);
		document.documentElement.style.setProperty("--c3c", settings.website.color2);

		if (settings.website) {
			var injectedStyles = document.createElement("style");
			injectedStyles.setAttribute("type", "text/css");
			injectedStyles.innerHTML = settings.website.css;
			document.head.appendChild(injectedStyles);
		}
	};

	const loadLabels = async () => {
		console.log("loadLabels");
		const response = await api.post("/labels/list/", {
			type: "website",
		});
		setLabels(response.rows);
	};

	const loadPages = async () => {
		console.log("loadPages");
		const res = await api.post("/pages/list/", {
			orderby: "title",
		});
		if (res.success == 0) alert(res.error);
		else setPages(res.rows);
	};

	const loadSlides = async () => {
		const response = await api.post("/slides/list/", {
			orderby: "position",
			src: [
				{ name: "active", value: 1, compare: "equal" },
				{ name: "website", value: 1, compare: "equal" },
			],
		});
		setLoading(false);
		if (response.success == 0)
			setConfirm({
				title: "Errore",
				message: response.error,
			});
		else setSlides(response.rows);
	};

	const loadNews = async () => {
		const response = await api.post("/news/list/", {
			orderby: "date DESC",
			lang: lang,
		});
		setLoading(false);
		if (response.success == 0)
			setConfirm({
				title: "Errore",
				message: response.error,
			});
		else setNews(response.rows);
	};

	const loadPopups = async () => {
		console.log("loadPopups");
		const res = await api.post("/popups/list/", {
			orderby: "title",
			src: [
				{ name: "active", value: 1, compare: "equal" },
				{ name: "website", value: 1, compare: "equal" },
			],
		});
		if (res.success == 0) alert(res.error);
		else setPopups(res.rows);
	};

	const loadShops = async () => {
		console.log("loadShops");
		const res = await api.post("/shops/list/", {
			fields: [
				"id",
				"name",
				"img",
				"address",
				"number",
				"zipcode",
				"city",
				"province",
				"phone",
				"email",
				"coord",
				"times_text",
				"delivery",
				"takeaway",
				"active_reservations",
				"description",
				"additional_info",
				"gallery_id",
				"reservations_ext_url",
			],
			orderby: "position",
			src: [
				{
					name: "active",
					value: 1,
					compare: "equal",
				},
			],
		});
		if (res.success == 0) alert(res.error);
		else setShops(res.rows);
	};

	async function loadProductsStock() {
		const response = await api.post("/products_stock/list_for_clients/");
		if (response.success == 0) alert(response.error);
		else setStock(response.rows);
	}

	const loadDiscounts = async () => {
		console.log("loadDiscounts");
		const res = await api.post("/discounts_products/list/", {
			customer_id: user ? user.id : null,
		});
		if (res.success == 0) alert(res.error);
		else setDiscounts(res.rows);
	};

	const loadMenu = async () => {
		console.log("loadMenu");
		const response = await api.post("/menu/courses/");
		if (response.success === 0) alert(response.error);
		else {
			setMenu(response.rows);
		}
	};

	function filterMenu() {
		console.log("filterMenu");
		let res = [];
		for (const course of menu) {
			let cr = JSON.parse(JSON.stringify(course));
			cr.categories = [];
			for (const category of course.categories) {
				if (category.active_website == 1) {
					let ct = JSON.parse(JSON.stringify(category));
					ct.products = [];
					for (const product of category.products) {
						if (product.active_website == 1) {
							let p = filterVariants(product);
							p.stock = getProductStock(p.id);
							if (shop && listId) {
								const priceList = product.prices.filter((el) => {
									return el.list_id == listId && el.price;
								});
								const assortment = product.assortment.filter((el) => {
									return (
										el.shop_id == shop.id &&
										el.list_id == listId &&
										el.active == 1
									);
								});
								if (
									priceList.length > 0 &&
									assortment.length > 0 &&
									checkIngredientsAssortment(product)
								) {
									p.price = priceList[0].price;
									ct.products.push(p);
								}
							} else if (shop) {
								if (checkIngredientsAssortment(product)) {
									ct.products.push(p);
								}
							} else {
								ct.products.push(p);
							}
						}
					}
					ct = applyDiscounts(ct);
					if (ct.products.length > 0) cr.categories.push(ct);
				}
			}
			if (cr.categories.length > 0) res.push(cr);
		}
		res = generatePromoCategory(res);
		setCourses(res);
	}

	const filterVariants = (product) => {
		let p = JSON.parse(JSON.stringify(product));
		p.variants_categories = [];
		for (const variants_category of product.variants_categories) {
			let vc = JSON.parse(JSON.stringify(variants_category));
			vc.variants = [];
			vc.variants2 = [];
			vc.products = [];
			for (const variant of variants_category.variants) {
				let v = JSON.parse(JSON.stringify(variant));
				if (shop && listId) {
					const priceList = variant.prices.filter((el) => {
						return el.list_id == listId && el.price !== null;
					});
					const assortment = variant.assortment.filter((el) => {
						return el.shop_id == shop.id && el.list_id == listId && el.active == 1;
					});
					if (priceList.length > 0 && assortment.length > 0) {
						v.price = priceList[0].price;
						vc.variants.push(v);
					}
				} else {
					vc.variants.push(v);
				}
			}
			for (const variant of variants_category.variants2) {
				let v = JSON.parse(JSON.stringify(variant));
				if (shop && listId) {
					const priceList = variant.prices.filter((el) => {
						return el.list_id == listId && el.price !== null;
					});
					const assortment = variant.assortment.filter((el) => {
						return el.shop_id == shop.id && el.list_id == listId && el.active == 1;
					});
					if (priceList.length > 0 && assortment.length > 0) {
						v.price = priceList[0].price;
						vc.variants2.push(v);
					}
				} else {
					vc.variants2.push(v);
				}
			}
			for (const subproduct of variants_category.products) {
				let v = JSON.parse(JSON.stringify(subproduct));
				if (shop && listId) {
					const priceList = subproduct.prices.filter((el) => {
						return el.list_id == listId && el.price !== null;
					});
					const assortment = subproduct.assortment
						? subproduct.assortment.filter((el) => {
							return (
								el.shop_id == shop.id && el.list_id == listId && el.active == 1
							);
						})
						: [1];
					if (
						priceList.length > 0 &&
						assortment.length > 0 &&
						checkIngredientsAssortment(subproduct)
					) {
						v.price = priceList[0].price;
						vc.products.push(v);
					}
				} else if (shop) {
					if (checkIngredientsAssortment(subproduct)) {
						vc.products.push(v);
					}
				} else {
					vc.products.push(v);
				}
			}
			if (vc.force_mono == 1) {
				vc.min = 0;
				vc.max = vc.variants.length + vc.variants2.length + vc.products.length;
			}
			if (vc.variants.length > 0 || vc.variants2.length > 0 || vc.products.length > 0)
				p.variants_categories.push(vc);
		}
		return p;
	};

	const getProductStock = (productId) => {
		if (!stock) return false;
		const s = stock.find((e) => e.shop_id == shop.id && e.product_id == productId);
		if (s) return s.qty;
		return false;
	};

	const checkIngredientsAssortment = (product) => {
		if (!settings.menu_api) return true;
		if (!settings.menu_api.enable_ingredients_assortment) return true;
		if (settings.menu_api.enable_ingredients_assortment != 1) return true;
		if (!product.ingredients) return true;
		if (product.ingredients.length == 0) return true;
		//console.log("check_ingredients_assortment", product.name);
		for (const ingredient of product.ingredients) {
			const assortment = ingredient.assortment.filter((el) => {
				return el.shop_id == shop.id && el.active == 1;
			});
			if (assortment.length == 0) return false;
		}
		return true;
	};

	const applyDiscounts = (category) => {
		//console.log("applyDiscount");
		for (const product of category.products) {
			if (discounts && discounts.length > 0 && cart.shop_id) {
				for (const discount of discounts) {
					if (product.id == discount.product_id) {
						//alert(JSON.stringify(discount.lists_ids));
						//alert(cart.list_id);
						//alert(discount.lists_ids.includes(cart.list_id.toString()));
						if (
							(discount.shops_ids.length == 0 ||
								discount.shops_ids.includes(cart.shop_id)) &&
							(!discount.lists_ids ||
								discount.lists_ids.includes(cart.list_id.toString())) &&
							/*(!discount.date_start ||
								moment(cart.delivery_date).startOf("day") >=
									moment(discount.date_start)) &&*/
							(!discount.date_end ||
								moment(cart.delivery_date).startOf("day") <=
								moment(discount.date_end)) &&
							(!discount.qty || discount.qty > 0)
						) {
							//alert("test");
							product.discounted = true;
							product.discount = {
								category: "discounts_products",
								one_per_customer: discount.one_per_customer,
								date_start: discount.date_start,
								date_end: discount.date_end,
								type: discount.type,
								value: discount.value,
								price:
									discount.type == "perc"
										? parseFloat(product.price) -
										(parseFloat(product.price) / 100) * discount.value
										: product.price - discount.value,
							};
						}
					}
				}
			} else {
				product.discounted = false;
				product.discount = null;
			}
		}
		return category;
	};

	const generatePromoCategory = (menu) => {
		const discountedProducts = [];
		for (const course of menu) {
			for (const category of course.categories) {
				for (const product of category.products) {
					if (product.discounted && product.discount.one_per_customer == 1)
						discountedProducts.push(product);
				}
			}
		}

		if (menu.length > 0)
			menu[0].categories.unshift({
				id: -2,
				name: <Label number={174} />,
				products: discountedProducts,
			});
		return menu;
	};

	const getTranslatedString = (obj, name) => {
		if (settings.mandant.multilanguage == 0) return obj[name];
		if (obj["translations"] && !obj["translations"][lang]) return obj[name];
		if (obj["translations"] && obj["translations"][lang][name])
			return obj["translations"][lang][name];
		return obj[name];
	};

	const loadCart = async () => {
		console.log("loadCart");
		let request = {
			lang: lang,
			hotel_id: hotel,
			locked_shop_id: shopLocked,
		};
		if (user) {
			const response = await api.postProtected("/cart/get/" + user.id + "/", request);
			setLoading(false);
			if (response.success === 0) alert(response.error);
			else {
				setCart(response.data);
				setListId(response.data.list_id);
				setShop(response.data.shop_id ? response.data.shop : false);
				return response.data;
			}
		} else {
			let tId;
			if (tempId) tId = tempId;
			else {
				tId = uuid();
				localStorage.setItem("website-temp-id", tId);
				setTempId(tId);
			}
			const response = await api.post("/temp_cart/get/" + tId + "/", request);
			setLoading(false);
			if (response.success === 0) alert(response.error);
			else {
				setCart(response.data);
				setListId(response.data.list_id);
				setShop(response.data.shop_id ? response.data.shop : false);
				return response.data;
			}
		}
	};

	const editCart = async (request) => {
		console.log("editCart");
		setLoading(true);
		if (user) {
			const response = await api.post("/cart/edit/" + user.id + "/", request);
			if (response.success === 0) {
				alert(response.error);
				setLoading(false);
			} else {
				if (request.hotel_id === null) setHotel(false);
				else loadCart(response.data);
			}
		} else {
			const response = await api.post("/cart/edit/" + tempId + "/", request);
			if (response.success === 0) {
				alert(response.error);
				setLoading(false);
			} else {
				if (request.hotel_id === null) setHotel(false);
				else loadCart(response.data);
			}
		}
	};

	const emptyCart = async (request) => {
		console.log("emptyCart");
		setConfirm({
			title: <Label number={38} />,
			onConfirm: async () => {
				setLoading(true);
				if (user) {
					const response = await api.post("/cart/empty/" + user.id + "/", request);
					if (response.success === 0) {
						alert(response.error);
						setLoading(false);
					} else loadCart(response.data);
				} else {
					const response = await api.post("/cart/empty/" + tempId + "/", request);
					if (response.success === 0) {
						alert(response.error);
						setLoading(false);
					} else loadCart(response.data);
				}
			},
		});
	};

	const handleAddProduct = (
		product,
		apply_discount = 0,
		selectedVariants = [],
		selectedSubroducts = [],
		notes = ""
	) => {
		console.log(
			"handleAddProduct",
			apply_discount,
			selectedVariants,
			selectedSubroducts,
			product.stock
		);
		if (!cartEnabled) setCartEnabled(true);
		else if (product.discounted && product.discount.one_per_customer == 1 && !user) {
			setLoginPopup(true);
		} else if (product.stock !== false && (product.stock == 0 || !checkCartQty(product))) {
			setConfirm({
				title: "Prodotto esaurito",
			});
		} else {
			addProduct(product, selectedVariants, selectedSubroducts, notes, apply_discount);
			setSelectedProduct(false);
		}
	};

	function checkCartQty(product) {
		console.log("checkCartQty");
		let cartQty = 0;
		for (const p of cart.products) {
			if (p.product_id == product.id) cartQty += parseInt(p.qty);
		}
		console.log(cartQty);
		if (cartQty < product.stock) return true;
		return false;
	}

	const addProduct = async (
		product,
		variants = [],
		subproducts = [],
		notes = "",
		apply_discount = 0
	) => {
		console.log("addProduct");
		let uId;
		if (user) uId = user.id;
		else uId = tempId;
		setLoading(true);
		const response = await api.post("/cart/add_product/" + uId + "/", {
			product_id: product.id,
			qty: 1,
			variants: variants,
			products: subproducts,
			notes: notes,
			apply_discount: apply_discount,
		});
		if (response.success === 0) {
			alert(response.error);
			setLoading(false);
		} else {
			loadCart(response.data);
			if (settings.menu_api?.enable_related_products_on_cart_add == 1) {
				if (loadRelated) getRelatedProducts(getCartProductsIds(), product.id);
				else setRelatedProducts(false);
			}
		}
	};

	const getCartProductsIds = () => {
		let res = [];
		for (const product of cart.products) {
			res.push(product.product_id);
		}
		return res;
	};

	const handleEditProduct = (product, qty) => {
		const p = cart.products.find((p) => p.item_id == product.item_id);
		const s = stock.find((s) => s.product_id == p.product_id && s.shop_id == shop.id);
		if (!cartEnabled) setCartEnabled(true);
		else if (qty < p.qty || s === undefined || parseInt(s.qty) > parseInt(p.qty)) {
			editProduct({
				item_id: product.item_id,
				qty: qty,
			});
		}
	};

	const editProduct = async (request) => {
		console.log("editProduct");
		setLoading(true);
		if (user) {
			const response = await api.postProtected(
				"/cart/edit_product/" + user.id + "/",
				request
			);
			if (response.success === 0) {
				alert(response.error);
				setLoading(false);
			} else loadCart(response.data);
		} else {
			const response = await api.post("/cart/edit_product/" + tempId + "/", request);
			if (response.success === 0) {
				alert(response.error);
				setLoading(false);
			} else loadCart(response.data);
		}
	};

	const getHomeComponent = () => {
		if (settings.website.hide_home == 1) {
			if (hasOrders) return <Store />;
			else return <Booking />;
		} else return <Home />;
	};

	const getRelatedProducts = async (ids, id = null) => {
		console.log("getRelatedProducts", ids);
		const response = await api.post("/related_products/get/", { ids: ids, id: id });
		if (response.success === 0) {
			alert(response.error);
		} else {
			//console.log(response);
			let res = [];
			for (const rpId of response.rows) {
				//console.log(rpId);
				for (const course of courses) {
					for (const category of course.categories) {
						if (category.id != -2) {
							for (const product of category.products) {
								let p = JSON.parse(JSON.stringify(product));
								if (product.id == rpId) res.push(p);
							}
						}
					}
				}
			}
			//console.log(res);
			if (res.length > 0) setRelatedProducts(res);
			else setRelatedProducts(false);
		}
	};

	const loadDiscountsLimit = async () => {
		console.log("loadDiscountsLimit");
		const res = await api.post("/discounts_limit/list/", {
			orderby: "order_min",
			src: [
				{
					name: cart.delivery_type == "delivery" ? "delivery" : "takeaway",
					compare: "equal",
					value: 1,
				},
			],
		});
		if (res.success == 0) alert(res.error);
		else setDiscountsLimit(res.rows);
	};

	function ErrorBoundary() {
		let error = useRouteError();
		console.log("Error found");
		console.error(error);
		// Uncaught ReferenceError: path is not defined
		return (
			<div style={{ textAlign: "center" }}>
				<h2>Si è verificato un errore</h2>
				<p>{error.message}</p>
				<p>{error.name}</p>
				<p>{error.stack}</p>
			</div>
		);
	}

	const router =
		settings &&
		createBrowserRouter(
			createRoutesFromElements(
				<Route path="/" element={<Layout />} errorElement={<ErrorBoundary />}>
					<Route path="/it" element={getHomeComponent()} />
					<Route path="/it/" element={getHomeComponent()} />
					<Route index element={getHomeComponent()} />
					<Route path="home" element={getHomeComponent()} />
					<Route path="login" element={<Login />} />
					<Route path="check_email" element={<LoginCheckEmail />} />
					<Route path="email_login" element={<LoginEmailLogin />} />
					<Route path="check_mobile" element={<LoginCheckMobile />} />
					<Route path="signup_mobile" element={<SignUpMobile />} />
					<Route path="check_code_mobile" element={<CheckCodeMobile />} />
					<Route path="mobile_login" element={<LoginMobileLogin />} />
					<Route path="check_password_code_sms" element={<CheckPasswordCodeSms />} />
					<Route path="password/new/:id/" element={<RestorePassword />} />
					<Route path="signup" element={<SignUp />} />
					<Route path="check_code" element={<CheckCode />} />
					<Route path="check_password_code" element={<CheckPasswordCode />} />
					<Route path="new_password" element={<NewPassword />} />
					<Route path="store" element={<Store />} />
					<Route path="store/:categoryId" element={<Store />} />
					<Route path="/login/sms" element={<Store />} />
					<Route
						path="checkout"
						element={
							settings.website.guest_mode == 0 && !user ? <Login /> : <Checkout />
						}
					/>
					<Route path="order_confirmed" element={<OrderConfirmed />} />
					<Route path="booking" element={<Booking />} />
					<Route path="booking_confirmed" element={<BookingConfirmed />} />
					<Route path="cms/:id" element={<Cms />} />
					<Route path="shops" element={<Shops />} />
					<Route path="shops/:id" element={<Shop />} />
					<Route path="userboard" element={<Userboard />} />
					<Route path="billing_address" element={<BillingAddress />} />
					<Route path="billing_address/:id" element={<BillingAddress />} />
					<Route path="orders" element={<Orders />} />
					<Route path="bookings" element={<Bookings />} />
					<Route path="bookings/:id" element={<BookingSheet />} />
					<Route path="points_transactions" element={<PointsTransactions />} />
					<Route path="credits_transactions" element={<CreditTransactions />} />
					<Route path="promo" element={<Promo />} />
					<Route path="news" element={<NewsList />} />
					<Route path="news/:id" element={<News />} />
					<Route path="*" element={<NotFound />} />
				</Route>
			)
		);

	if (lock || !settings)
		return <div className="lock">{settings && <img src={settings.website.logo} />}</div>;

	return (
		<GoogleOAuthProvider clientId={settings.google.client_id}>
			<AppContext.Provider
				value={{
					settings,
					shops,
					setLoading,
					brand,
					setBrand,
					pages,
					labels,
					lang,
					setLang,
					getTranslatedString,
					courses,
					category,
					setCategory,
					cart,
					editCart,
					emptyCart,
					addProduct,
					editProduct,
					confirm,
					setConfirm,
					user,
					setUser,
					loadCart,
					lastOrderId,
					setLastOrderId,
					tempId,
					pagePopup,
					setPagePopup,
					reloadUser,
					tempUser,
					setTempUser,
					loginPopup,
					setLoginPopup,
					track,
					discounts,
					news,
					menus,
					slides,
					popups,
					mainSettings,
					hotel,
					hasOrders,
					hasReservations,
					loginRedirect,
					setLoginRedirect,
					c1,
					setC1,
					cookieSettingsPopupVisible,
					setCookieSettingsPopupVisible,
					setHotel,
					lastOrderTotal,
					setLastOrderTotal,
					hasDelivery,
					hasTakeaway,
					handleAddProduct,
					cartEnabled,
					setCartEnabled,
					selectedProduct,
					setSelectedProduct,
					relatedProducts,
					setRelatedProducts,
					setLoadRelated,
					storePopupVisible,
					setStorePopupVisible,
					loadRelated,
					getRelatedProducts,
					discountsLimit,
					stock,
					handleEditProduct,
				}}
			>
				{settings.website.maintenance == 1 ? (
					<Maintenance />
				) : (
					<>
						{!brand ? (
							<Brands />
						) : (
							<>
								{<RouterProvider router={router} />}
								{pagePopup && <PagePopup />}
								{relatedProducts && <RelatedProductsPopup />}
								{selectedProduct && (
									<ProductSheet
										product={selectedProduct}
										setSelectedProduct={setSelectedProduct}
									/>
								)}
								{storePopupVisible && <StorePopUp />}
								<CmsPopups />
								<Confirm />
							</>
						)}
					</>
				)}

				{loading && (
					<div id="loading">
						<div className="content">
							<LoadIcon className="fa-spin"></LoadIcon>
						</div>
					</div>
				)}
			</AppContext.Provider>
		</GoogleOAuthProvider>
	);
}

export default App;
